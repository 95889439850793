<template>
    <div class="container latest-posts-wrapper">
        <div class="row" v-if="shuffledPosts && shuffledPosts.length > 0">
            <popular-posts :posts="shuffledPosts" :latest="true"></popular-posts>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { chunk, shuffle } from "lodash";
import PopularPosts from "../views/blog/article/components/PopularPosts";

export default {
    name: "latestPosts",
    components: {
        "popular-posts": PopularPosts,
    },
    computed: {
        ...mapState("willyou", ["latestPosts"]),
        shuffledPosts() {
            if (typeof this.latestPosts === "string") return JSON.parse(this.latestPosts);
            else return this.latestPosts;
        },
    },
};
</script>

<style lang="scss" scoped>
.container.latest-posts-wrapper {
    margin-bottom: 1.5rem;

    .row {
        justify-content: center;
    }
}
</style>
