<template>
    <div id="categories">
        <div class="row">
            <div class="title mb-3">{{ getTitle }}</div>
        </div>
        <div class="row">
            <div v-for="(post, index) in posts" :key="index" class="col-12 col-sm-6 col-md-4">
                <article-card :article="getArticle(post)" :latest="latest"></article-card>
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from "../../components/ArticleCard";
import { mapState } from "vuex";

export default {
    name: "popular-posts",
    props: ["latest", "posts", "users", "headline"],
    components: {
        "article-card": ArticleCard,
    },
    methods: {
        getArticle(article) {
            let author = this.users ? this.users.filter((user) => user.id === userID) : [{ name: "Willyou.net" }];
            article.author = author && author[0] ? author[0] : article.author;
            if (this.latest === true) return article;

            let userID = article.author;
            let highestCategory = Math.max(...article.categories);
            let selectedCategory = this.articlesCategories
                ? this.articlesCategories.filter((generalCategory) => generalCategory.id === highestCategory)[0]
                : [];
            article.selectedCategory = selectedCategory;
            return article;
        },
    },
    computed: {
        ...mapState("willyou", ["articlesCategories"]),
        getTitle() {
            if (this.latest === true) return "Must-Know Local & Jeweler Insights";
            if (typeof this.headline !== "undefined") return this.headline;
            return this.posts && this.posts[0] && this.posts[0].categories && this.posts[0].categories.find((c) => c === 7)
                ? `More ${this.getStateFromRoute} articles:`
                : "Related articles:";
        },
        getStateFromRoute() {
            return this.$route.path.split("/")[this.$route.path.split("/").length - 2].toUpperCase();
        },
    },
};
</script>

<style lang="scss" scoped>
#categories {
    width: 100%;

    .title {
        font-family: "Noto Serif SC", serif;
        font-size: 24px;
        font-weight: 200;
        color: rgba(0, 0, 0, 0.87);
    }
}

@media screen and (max-width: 575px) {
    #categories {
        .title {
            margin-left: 1.75rem;
        }
    }
}
</style>
