<template>
    <div id="article-card" class="card m-2" v-if="article">
        <div class="image-container">
            <img :src="getImage" class="img-responsive" alt="..." />
            <div v-if="article.categories" class="bottom-left rnd-secondary-btn text-uppercase">{{ categoryName }}</div>
        </div>
        <div class="card-body text-center">
            <!-- <h5 class="author">{{ `${getPostDate(article.date)} By ${authorName}` }}</h5> -->
            <router-link :to="getLink">
                <h3 class="title" v-html="article.title.rendered"></h3>
                <span class="rnd-secondary-btn text-uppercase read-more-button">READ MORE</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { firstCapitalLetter } from "../../../helpers";
export default {
    props: ["article", "latest"],
    data() {
        return {
            articleCategories: [],
        };
    },
    methods: {
        ...mapActions("willyou", ["getUsers"]),
        getPostDate(date) {
            return moment(date).format("ll");
        },
    },
    computed: {
        ...mapState("willyou", ["blogUsers"]),
        getImage() {
            if (this.article && this.article.yoast_meta) {
                const ogImageMeta = this.article.yoast_meta.find((meta) => meta.property === "og:image");
                return ogImageMeta ? ogImageMeta.content : "";
            }
            return "";
        },
        getLink() {
            // const arrLink = this.article && this.articleCategories ? this.article.link.split('/') : [];
            // const permalink = arrLink && arrLink.length ? arrLink[arrLink.length - 2] : null;
            let link = `/blog${this.article.link.slice(24)}`;
            link = link.replace(/\/$/, "").replace("-directoryseperator-", "/").replace("category-articles/", "");
            return link;
        },
        authorName() {
            return this.article.author && this.article.author.name ? this.article.author.name : "Willyou.net";
        },
        categoryName() {
            const link = this.getLink.split("/")[2].replaceAll("-", " ");

            return this.article || this.article.selectedCategory.name === "category articles" || this.article.selectedCategory.name === "category-articles"
                ? firstCapitalLetter(link)
                : this.article.selectedCategory.name;
        },
    },
    mounted() {
        this.blogUsers.length === 0 ? this.getUsers() : null;
        // this.article._links ? axios.get(this.article._links.author[0].href)
        //         .then(response => this.articleAuthor = response.data.name) : null;
        // axios.get(`https://blog.willyou.net/wp-json/wp/v2/comments?post=${value.id}`)
        //     .then(res => this.blogComments = res.data)
        // axios.get(`https://blog.willyou.net/wp-json/wp/v2/tags?post=${value.id}`)
        //     .then(res => this.blogTags = res.data)
        // axios.get(`https://blog.willyou.net/wp-json/wp/v2/categories?post=${this.article.id}`)
        //     .then(res => this.articleCategories = res.data);
    },
};
</script>

<style lang="scss" scoped>
#article-card {
    font-family: "Noto Sans", sans-serif;
}
.read-more-button {
    border: none;
}
.image-container {
    max-height: 180px;
    overflow: hidden;
    img {
        height: 180px;
        width: auto;
    }
}
.message-area {
    max-width: 700px;
    .noto-sans-font-title {
        padding: 0;
    }
}
#article-card .title {
    font-family: Noto Sans, sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    margin: 0;
}
.author {
    font-family: Noto Sans KR, sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #4a4a4a;
}
.bottom-left {
    color: #ff0453;
    background-color: #333333;
    opacity: 0.8;
    position: absolute;
    border: none;
}
.image-container {
    display: flex;
    flex-direction: column-reverse;
}
@media (max-width: 1200px) {
    .image-container {
        height: 147px;
        overflow: hidden;
        img {
            height: 147px;
            width: auto;
        }
    }
}
@media (max-width: 992px) {
    .image-container {
        height: 102px;
        overflow: hidden;
        img {
            height: 102px;
            width: auto;
        }
    }
}
@media (max-width: 767px) {
    .image-container {
        height: 147px;
        overflow: hidden;
        img {
            height: 147px;
            width: auto;
        }
    }
}
</style>
