<template>
    <div class="jeweler-ai-banner-wrapper">
        <div class="jeweler-ai-banner-inner">
            <div class="text-side">
                <h2 class="home-title">Design Your Ring with Jeweler AI</h2>
                <p class="noto-sans-font-title mb-4 pb-0 text-center text-lg-left">
                    The smart, stress-free path to creating an engagement ring that’s uniquely yours
                </p>
                <a href="https://www.willyou.com/jewelerai" class="btn rnd-primary-btn">START NOW</a>
            </div>

            <Lottie class="lottie" />
        </div>
    </div>
</template>

<script>
export default {
    name: "JewelerAiBanner",
    props: ["src", "alt", "classs"],
    components: {
        Lottie: () => import("@/willyou/components/Lottie.vue"),
    },
};
</script>
<style lang="scss" scoped>
.jeweler-ai-banner-wrapper {
    width: 100%;
    padding: 80px 0;
    background-color: rgb(255, 245, 248);
    display: flex;
    justify-content: center;
    align-items: center;
    .jeweler-ai-banner-inner {
        max-width: 850px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        .lottie {
            width: 28%;
            margin-left: 40px;
        }
    }

    @media (max-width: 992px) {
        padding: 40px 0;

        .jeweler-ai-banner-inner {
            flex-direction: column-reverse;
            padding: 0 40px;

            .lottie {
                width: 120px;
                margin: 40px 0;
            }

            .text-side {
                text-align: center;
            }
        }
    }
}
</style>
